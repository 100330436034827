import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/index'
import './styles/amigos-do-bairro.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'

const BackgroundDegrade = (data) => {
  return (
    <div className="bg-graydegrade">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <GatsbyImage image={data.image} alt="Imagem" />
          </div>
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="videoAmigos mb-2">
              <ReactPlayer url={data.link} width='600' height='600' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BackgroundRed = (data) => {
  return (
    <div className="bg-redamigos">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-5 d-flex align-items-center justify-content-center order-2 order-md-1">
            <div className="videoAmigos mb-2 mb-lg-0 mt-0 mt-md-2">
              <ReactPlayer url={data.link} width='600' height='600' />
            </div>
          </div>
          <div className="col-lg-7 order-1 order-md-2">
            <GatsbyImage image={data.image} alt="Imagem" />
          </div>
        </div>
      </div>
    </div>
  )
}

const Footer = (data) => {
  return (
    <div className="bg-footeramigos">
      <div className="container p-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-4 d-flex justify-content-center">
            <h2 className="text-white font-Gotham font-weight-bold font-size-15 line-height-25px">Só quem tem<br/> ClubDIA, aproveita<br/> ofertas exclusivas.<br/><span className="text-yellow font-Gotham">Baixe agora nosso<br/> App e cadastre-se.</span></h2>
          </div>
          <div className="col-lg-3 col-10 mb-lg-0 mb-3 mt-3">
            <GatsbyImage image={data.clubDia} alt="icone do ClubDia" />
          </div>
          <div className="col-lg-4 col-10 justify-content-center d-flex">
            <div className="col-lg-8">
              <a href="https://play.google.com/store/apps/details?id=br.com.dia.customers&amp;referrer=utm_source%3Dsite%26utm_medium%3Dbutton%26utm_campaign%3Ddownloadapp" target="_blank" rel="noopener noreferrer">
                <GatsbyImage image={data.googleplay} alt="icone do Google Play" className="mb-3" />
              </a>
              <a href="https://click.google-analytics.com/redirect?tid=UA-26046188-5&amp;url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmeu-desconto-clubdia%2Fid1311617705%3Fmt%3D8&amp;aid=br.com.dia.customers&amp;idfa=%{idfa}&amp;cs=site&amp;cm=button&amp;cn=downloadapp" target="_blank" rel="noopener noreferrer">
                <GatsbyImage image={data.appstore} alt="icone do APP Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AmigosdoBairro = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'white',
    items: [
      { path: '/', name: 'Home' },
      { path: '/amigos-do-bairro/', name: 'Amigos do Bairro' }
    ]
  }
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Amigos do Bairro - Dia Supermercados</title>
        <meta name="description" content="Amigos do Bairro ClubDIA" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <GatsbyImage
        image={data.bannerDesktop.childImageSharp.gatsbyImageData}
        className="d-lg-block d-none" />
      <GatsbyImage
        image={data.bannerMobile.childImageSharp.gatsbyImageData}
        className="d-lg-none d-block" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-12 d-flex text-center mt-5 mb-5">
            <h4 className="color-red font-Movement font-weight-bold mb-0">
              O DIA apresenta os AMIGOS DO BAIRRO. Conheça cada um desses personagens.
              Eles vão dar dicas, falar sobre oportunidades, contar experiências e novidades
              pra deixar todo mundo atualizado.
            </h4>
          </div>
        </div>
      </div>
      <BackgroundDegrade image={data.imgGabriel.childImageSharp.gatsbyImageData} link={'https://youtu.be/RTu3wfTSA9s'} />
      <BackgroundRed image={data.imgMarta.childImageSharp.gatsbyImageData} link={'https://youtu.be/WQy1LD-Cr_w'} />
      <BackgroundDegrade image={data.imgOsvaldinho.childImageSharp.gatsbyImageData} link={'https://youtu.be/kgp7P1nc2ZY'} />
      <BackgroundRed image={data.imgCarol.childImageSharp.gatsbyImageData} link={'https://youtu.be/Z6tSdNSdiZM'} />
      <BackgroundDegrade image={data.imgMeuCasal.childImageSharp.gatsbyImageData} link={'https://youtu.be/YW8vBmDPGu4'} />
      <Footer clubDia={data.clubDia.childImageSharp.gatsbyImageData} appstore={data.iconPlayStore.childImageSharp.gatsbyImageData} googleplay={data.iconGooglePlay.childImageSharp.gatsbyImageData} />
    </Layout>
  )
}

export default AmigosdoBairro

export const query = graphql`{
  bannerDesktop: file(relativePath: {eq: "amigos-do-bairro/banner-desktop.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobile: file(relativePath: {eq: "amigos-do-bairro/bannerMobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 345, height: 290, layout: CONSTRAINED)
    }
  }
  imgGabriel: file(relativePath: {eq: "amigos-do-bairro/gabriel.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgMarta: file(relativePath: {eq: "amigos-do-bairro/marta.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgOsvaldinho: file(relativePath: {eq: "amigos-do-bairro/osvaldinho.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgCarol: file(relativePath: {eq: "amigos-do-bairro/carol.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgMeuCasal: file(relativePath: {eq: "amigos-do-bairro/meu-casal.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  clubDia: file(relativePath: {eq: "amigos-do-bairro/clubdia.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  iconGooglePlay: file(relativePath: {eq: "amigos-do-bairro/gplay.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  iconPlayStore: file(relativePath: {eq: "amigos-do-bairro/appsotre.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
